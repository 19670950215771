/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    InputBase,
    IconButton,
    Tooltip,
    Snackbar,
    Alert,
    Chip,
    Button,
    Menu,
    MenuItem,
} from "@mui/material";
import { Search as SearchIcon, FilterList, Delete, Edit, MoreVert } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Loader from "../../../components/Loader";
import { UserContext } from "../../../context/UserContext";
import { getAllTickets } from "../../../api/ticket";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f0f0f0",
    "&:hover": {
        backgroundColor: "#f7f9fc",
    },
    marginLeft: 0,
    marginRight: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

const headers = [
    { id: "title", label: "Ticket Title" },
    { id: "users", label: "Assigned Users" },
    { id: "status", label: "Status" },
    { id: "created_at", label: "Created By" },
    { id: "updated_at", label: "Last Updated At" },
    { id: "action", label: "Action" },


];

const TicketTable = ({
    refresh,
    setDetailOpen,
    setTicketDetail,
    ticketId,
    setTicketId,
    setUpdateOpen,
    setAssignOpen

}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [alertOpen, setAlertOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0)
    const { userData } = useContext(UserContext);
    const permission = userData.user.role_id.permissions

    useEffect(() => {
        getAllTickets(userData.user.company_id._id, setRows, setAlertOpen, setLoading, page, rowsPerPage, setTotal);
    }, [page, rowsPerPage, refresh]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setTicketId(null)
    };

    const handleDialogClose = () => {
        setDetailOpen(false)
    }

    return (
        <>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={"error"}
                    sx={{ width: "100%" }}
                >
                    Error in fetching tickets. Please try after some time
                </Alert>
            </Snackbar>

            {loading ? (
                <Loader data="Tickets" />
            ) : (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <div
                        style={{
                            margin: "16px 8px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6">Company Tickets</Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Tickets"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
                            <IconButton onClick={(e) => {
                                // setFilterOpen(true);
                            }}>
                                <Tooltip title="Filters" arrow placement="top">
                                    <FilterList />
                                </Tooltip>
                            </IconButton>

                        </div>
                    </div>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row.title}
                                            </TableCell>
                                            <TableCell>
                                                {row.assigned_users.length === 0 ? "No user assigned" : row.assigned_users.map((user) => {
                                                    return (
                                                        <>
                                                            <Chip label={user.full_name} /> {" "}
                                                        </>
                                                    )
                                                })}
                                            </TableCell>
                                            <TableCell>
                                                <Chip variant="outlined" color={row.status === "open" ? "error" : row.status === "in_progress" ? "warning" : "success"} label={row.status.toUpperCase().replaceAll("_", " ")} />
                                            </TableCell>

                                            <TableCell>{row.created_by ? row.created_by.full_name : "Auto"}</TableCell>
                                            {/* {permission !== undefined && permission.includes("UPDATE_TICKET_STATUS") ? (
                                                <TableCell>
                                                    <Button variant="outlined"
                                                        disabled={permission !== undefined && !Object.values(permission).includes("UPDATE_TICKET_STATUS")}
                                                        color={row.status === "open" ? "error" : row.status === "in_progress" ? "warning" : "success"}>{row.status.toUpperCase().replaceAll("_", " ")}</Button>
                                                </TableCell>

                                            ) : (
                                                <TableCell>
                                                    <Chip variant="outlined" color={row.status === "open" ? "error" : row.status === "in_progress" ? "warning" : "success"} label={row.status.toUpperCase().replaceAll("_", " ")} />
                                                </TableCell>
                                            )} */}



                                            <TableCell>
                                                {new Date(row.updatedAt).toLocaleString("en-IN", {
                                                    timeZone: "Asia/Kolkata",
                                                })}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    id="demo-positioned-button"
                                                    aria-controls={row._id === ticketId ? 'demo-positioned-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={row._id === ticketId ? 'true' : undefined}
                                                    onClick={(e) => {
                                                        handleClick(e)
                                                        setTicketId(row._id)

                                                    }}
                                                >
                                                    <MoreVert />
                                                </IconButton>
                                                <Menu
                                                    id="demo-positioned-menu"
                                                    aria-labelledby="demo-positioned-button"
                                                    anchorEl={anchorEl}
                                                    open={row._id === ticketId}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuItem onClick={() => {
                                                        setDetailOpen(true)
                                                        handleClose()
                                                        setTicketDetail(row)
                                                    }}>View Details</MenuItem>
                                                    {permission && permission.includes("ASSIGN_TICKET") && (
                                                        <MenuItem onClick={() => {
                                                            setAssignOpen(true)
                                                            handleClose()
                                                            setTicketDetail(row)
                                                        }}>Assign ticket</MenuItem>

                                                    )}
                                                    {permission && permission.includes("UPDATE_TICKET_STATUS") && (
                                                        <MenuItem onClick={() => {
                                                            setUpdateOpen(true)
                                                            handleClose()
                                                            setTicketDetail(row)
                                                        }}>Update Status</MenuItem>

                                                    )}
                                                </Menu>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}


        </>
    );
};

export default TicketTable;
