// Log filters

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";

export default function ViewDetail({
    detailOpen,
    handleDetailClose,
    ticketDetail
}) {

    const ticketLogs = ticketDetail.update_logs.reverse()
    // console.log(ticketLogs[0], 'ticket logs', typeof (ticketDetail.update_logs))

    return (
        <>
            <Dialog
                open={detailOpen}
                onClose={handleDetailClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">{ticketDetail.title}</DialogTitle>
                <DialogContent>
                    {/* {/* <TextField
              type="datetime-local"
              label="Start Time"
              name="start_time"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={filterData.start_time}
              onChange={(e) => {
                setFilterData({ ...filterData, start_time: e.target.value });
              }}
            /> */}
                    <TextField
                        label="Description"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={5}
                        maxRows={10}
                        value={ticketDetail.description}


                    />
                    <TextField
                        label="Created At"
                        fullWidth
                        margin="normal"
                        value={new Date(ticketDetail.createdAt).toLocaleString("en-IN")}


                    />
                    <TextField
                        label="Created by"
                        fullWidth
                        margin="normal"
                        multiline
                        value={ticketDetail.created_by ? ticketDetail.created_by.full_name : "Auto/System"}

                    />
                    <Timeline>
                        {ticketLogs.map((log) => (
                            <>
                                <TimelineItem position="alternate">
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="subtitle2" color="secondary">
                                            {log.message}
                                        </Typography>
                                        <Typography variant="subtitle2" color="primary">
                                            {new Date(log.time).toLocaleString("en-In")}
                                        </Typography>
                                        {log.update_by && (
                                            <Typography variant="subtitle2" color="tertiary" >
                                                Action By  {log.update_by.full_name}
                                            </Typography>
                                        )}
                                    </TimelineContent>
                                </TimelineItem>
                            </>
                        ))}


                    </Timeline>

                </DialogContent>
                <DialogActions>
                    {/* <Button
              color="error"
              variant="outlined"
              onClick={(e) => {
                setFilterData({ end_time: "", start_time: "" });
                setRefresh(!refresh);
                handleFilterClose();
              }}
            >
              Reset Filter
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                setRefresh(!refresh);
                handleFilterClose();
              }}
            >
              Set filter
            </Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
}
