import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Map from "./Map";
import { fetchAllDevices } from "../../api/device";
import { UserContext } from "../../context/UserContext";

const Location = () => {
    const { userData } = useContext(UserContext);
    const cityData = {
        lat: parseFloat(userData.user.company_id.latitude),
        lng: parseFloat(userData.user.company_id.longitude)
    }
    const [deviceData, setDeviceData] = useState([])
    const [deviceLoading, setDeviceLoading] = useState(true)

    useEffect(() => {
        fetchAllDevices(userData.user.company_id._id, setDeviceData, setDeviceLoading)
    }, []);

    return (
        <Box
            sx={{
                height: "calc(100vh - 128px)",
                width: "100%",
            }}
        >
            {deviceLoading ? (
                <>Map is loading...</>
            ) : (
                <>
                    <Map cityData={cityData} deviceData={deviceData} />

                </>

            )}
        </Box>
    );
};

export default Location;
