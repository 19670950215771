/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  MenuItem,
  ListSubheader,
  InputLabel,
  Select,
  FormControl,
  Button,
} from "@mui/material";
import { Cached, Download, FilterList } from "@mui/icons-material";
import { UserContext } from "./../../../context/UserContext";
// import { getDeviceLogs } from "../../../api/device";
import { getDeviceLogs, getInitialLogs } from "../../../api/logs";
import { CSVLink } from "react-csv";

const headers = [
  { key: "user_name", label: "User Name", align: "left" },
  { key: "device_id", label: "Device", align: "left" },
  { key: "action", label: "Action", align: "left" },
  { key: "timestamp", label: "Timestamp", align: "left" },
];

const LogsTable = ({
  deviceData,
  loading,
  setFilterOpen,
  refresh,
  setRefresh,
  formState,
  filterData,
  setFormState,
  setFilterData,
  GroupData,
  deviceLoad,
  SelectedGroups,
  setSelectedGroups,
  fetch,
  setFetch,
}) => {
  const { userData } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [alertOpen, setAlertOpen] = useState(false);
  const [logData, setLogData] = useState([]);
  const [severity, setSeverity] = useState();
  const [deviceId, setDeviceId] = useState();
  const [message, setMessage] = useState();
  const [total, setTotal] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchDeviceLogs = async () => {
    setTableLoading(true);
    let filter = {
      action: formState.action,
      start_time:
        filterData.start_time !== ""
          ? new Date(filterData.start_time).toISOString()
          : "",
      end_time:
        filterData.end_time !== ""
          ? new Date(filterData.end_time).toISOString()
          : "",
    };

    await getDeviceLogs(
      deviceId,
      userData.user._id,
      userData.user.company_id._id,
      page,
      rowsPerPage,
      filter,
      setLogData,
      setTotal,
      setTableLoading,
      setAlertOpen,
      setSeverity,
      setMessage
    );
  };

  useEffect(() => {
    if (deviceId !== undefined) {
      fetchDeviceLogs();
    }
  }, [deviceId, page, rowsPerPage, refresh]);

  useEffect(() => {
    getInitialLogs(userData.user._id, userData.user.company_id._id, page,
      rowsPerPage,
      setLogData,
      setTotal,
      setTableLoading,
      setAlertOpen,
      setSeverity,
      setMessage)
  }, [])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };


  // let sel_device = deviceData.filter((item) => item._id === deviceId);
  let ld = [];
  logData.forEach((element) => {
    ld.push({
      ...element,
      device_id: element.device_id + "-" + selectedDevice.device_description,
      timestamp: new Date(element.createdAt).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
      }),
    });
  });

  const csvReport = {
    filename: `Logs.csv`,
    headers: headers,
    data: ld,
  };

  console.log(logData, 'logdata')


  return (
    <>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          {!loading && (
            <>
              <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <InputLabel htmlFor="Select Device">Select Device</InputLabel>
                <Select
                  defaultValue=""
                  id="select-device"
                  label="Select Device"
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                    setPage(0);
                    setFilterData({ end_time: "", start_time: "" });
                    setFormState({ action: [] });
                  }}
                >
                  {deviceData.map((group) => [
                    <ListSubheader key={group.group_name}>{group.group_name}</ListSubheader>,
                    group.device_data.map((device) => (
                      <MenuItem key={device.device_id} value={device._id} onClick={() => setSelectedDevice(device)}>
                        {device.device_id + "- " + device.device_description}
                      </MenuItem>
                    )),
                  ])}
                </Select>

              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
      <Paper sx={{
        width: "100%", overflow: "hidden", marginBottom: `${window.matchMedia("(display-mode: standalone)").matches ||
          window.matchMedia("(max-width: 767px)").matches ? "40px" : "unset"}`
      }}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <div
          style={{
            margin: "16px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "left",
          }}
        >
          <Typography variant="h6">Logs</Typography>
          <div style={{ display: "flex", alignItems: "left" }}>
            {logData.length !== 0 && (
              <>
                <CSVLink
                  style={{ color: "green", textDecoration: "none" }}
                  {...csvReport}
                >
                  <Button
                    variant="outlined"
                    sx={{ ml: 2, mr: 2 }}
                    endIcon={<Download />}
                  >
                    CSV
                  </Button>
                </CSVLink>
              </>
            )}
            {/* <IconButton
              onClick={(e) => {
                setFilterOpen(true);
              }}
            >
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton> */}
            <IconButton
              onClick={(e) => {
                setFilterOpen(true);
              }}
            >
              <Tooltip title="Filters" arrow placement="top">
                <FilterList />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={(e) => {
                setPage(0);
                setRefresh(!refresh);
              }}
            >
              <Tooltip title="Refresh" arrow placement="top">
                <Cached />
              </Tooltip>
            </IconButton>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableLoading ? (
              <TableBody><TableRow><TableCell>Loading data...</TableCell></TableRow></TableBody>
            ) : (
              <TableBody>
                {logData.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell align="left">
                        {row.user_name !== undefined
                          ? row.user_name === "device" ? "Luminox" : row.user_name
                          : row.user_id}
                      </TableCell>
                      <TableCell align="left">
                        {row.device_id} - {row.device_description ? row.device_description : selectedDevice.device_description}
                      </TableCell>
                      <TableCell align="left">{row.action}</TableCell>
                      <TableCell align="left">
                        {new Date(row.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 75, 250]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Create new device dialog */}
    </>
  );
};

export default LogsTable;
