// All the apis of user operation

import axiosIntance from "../helper/axios";

const fetchAllUserCompany = (
  company_id,
  page,
  limit,
  table,
  setUsData,
  setLoading,
  setTotal,
  setAlertOpen,
  setSeverity,
  setMessage,
  searchQuery
) => {
  // setLoading(true);
  if (table) {
    axiosIntance
      .get(`/user/company/all/${company_id}?page=${page + 1}&limit=${limit}&searchTerm=${searchQuery}&table=${table}`)
      .then((res) => {
        setUsData(res.data.data.user_data);
        setLoading(false);
        setTotal(res.data.data.metadata.total_results);
      })
      .catch((err) => {
        console.log(err, "error");
        setLoading(false);
        setAlertOpen(true);
        setSeverity("error");
        setMessage("Error in fetching user data");
      });
  }
  else {
    setLoading(true)
    axiosIntance.get(`/user/company/all/${company_id}?table=${table}`).then((res) => {
      let resData = res.data.data
      let arr = []
      resData.map((dt) => arr.push(dt.user_id))
      setUsData(arr)
      setLoading(false)

    }).catch((err) => {
      console.log(err, 'err')
      setLoading(false)

    })
  }
};

const registerUser = (
  data,
  company_id,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .post(`/user/register/${company_id}`, data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

const updateUser = (
  userId,
  data,
  setSeverity,
  setMessage,
  setAlertOpen,
  handleFormClose
) => {
  axiosIntance
    .put(`/user/update/${userId}`, data)
    .then((res) => {
      setSeverity("success");
      setMessage(res.data.message);
      setAlertOpen(true);
      handleFormClose();
    })
    .catch((err) => {
      console.log(err);
      setAlertOpen(true);
      setSeverity("error");
      setMessage(err.response.data.error);
    });
};

export { fetchAllUserCompany, registerUser, updateUser };
