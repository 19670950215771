const routes = {
  // Public
  landing: "/",
  login: "/login",
  register: "/register",
  forget: "/forget-password",
  reset: "/reset-password",

  // Private
  dashboard: "/dashboard",
  masterControl: "/dashboard/master",
  location: '/dashboard/map',

  // Nested Routes: Dashboard

  user: "/dashboard/user",
  // Nested routes : User
  profile: "/dashboard/profile",
  userCreate: "/dashboard/user/create",
  updateUser: "/dashboard/user/update/:id",

  // Energy Route
  energy: "/dashboard/energy",
  energyChart: "/dashboard/energy/:device_id",

  // Nested routes: Group
  group: "/dashboard/group",
  groupCreate: "/dashboard/group/create",
  updateGroup: "/dashboard/group/update/:id",

  role: "/dashboard/role",

  // Plans
  plans: "/dashboard/plans",

  // Transactions
  transaction: "/dashboard/transactions",

  //Company Selection
  company: "/company",

  //Notification
  notification: "/app/notifications",
  energyNotification: "/dashboard/energy-notification",

  // Ticket
  ticket: "/dashboard/tickets",

  //Logs
  logs: "/dashboard/logs",

  //Setting
  setting: "/dashboard/setting",
  notificationSetting: "/dashboard/setting/notification",
  about: "/dashboard/setting/about",
};

export default routes;
