import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Typography, Breadcrumbs, Divider, Link, Box, Snackbar, Alert } from "@mui/material";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import TicketTable from "./element/TicketTable";
import ViewDetail from "./element/ViewDetails";
import UpdateStatus from "./element/UpdateTicketStatus";
import AssignUser from "./element/AssingUser";

const PageTitle = styled(Typography)`
  margin: 0px 0px 0.35em;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
`;

const PathLink = styled(Link)`
  margin: 0px;
  color: rgb(55, 111, 208);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const ActiveLink = styled(Typography)`
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
`;

const Tickets = () => {
  const [ticketId, setTicketId] = useState()
  const [detailOpen, setDetailOpen] = useState(false)
  const [ticketDetail, setTicketDetail] = useState({})
  const handleDetailClose = () => {
    setDetailOpen(false)
    setTicketDetail({})
    setUpdateOpen(false)
    setRefresh(!refresh)
    setAssignOpen(false)
  }
  const [refresh, setRefresh] = useState(false)
  const [updateOpen, setUpdateOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [assingOpen, setAssignOpen] = useState(false)
  const handleAlertClose = () => {
    setAlertOpen(false);
  };




  return (
    <div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <PageTitle>Tickets</PageTitle>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <PathLink underline="hover" href="/dashboard">
          Dashboard
        </PathLink>
        <ActiveLink>Tickets</ActiveLink>
      </Breadcrumbs>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <TicketTable setDetailOpen={setDetailOpen} setTicketDetail={setTicketDetail} setTicketId={setTicketId} ticketId={ticketId}
        setUpdateOpen={setUpdateOpen}
        refresh={refresh}
        setAssignOpen={setAssignOpen}
      />
      {detailOpen && (
        <ViewDetail
          detailOpen={detailOpen}
          handleDetailClose={handleDetailClose}
          ticketDetail={ticketDetail}

        />
      )}
      {updateOpen && (
        <UpdateStatus
          statusOpen={updateOpen}
          handleClose={handleDetailClose}
          ticketDetail={ticketDetail}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          handleDetailClose={handleDetailClose}

        />
      )}
      {assingOpen && (
        <AssignUser
          statusOpen={assingOpen}
          handleClose={handleDetailClose}
          ticketDetail={ticketDetail}
          setAlertOpen={setAlertOpen}
          setSeverity={setSeverity}
          setMessage={setMessage}
          handleDetailClose={handleDetailClose}

        />
      )}
    </div>
  );
};

export default Tickets;
