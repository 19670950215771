// Log filters

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { updateTicketStatus } from "../../../api/ticket";
import { UserContext } from "../../../context/UserContext";

export default function UpdateStatus({
    statusOpen,
    handleClose,
    ticketDetail,
    setAlertOpen,
    setSeverity,
    setMessage,
    handleDetailClose

}) {

    const { userData } = useContext(UserContext);
    const companyId = userData.user.company_id._id
    const [ticketStatus, setTicketStatus] = useState(ticketDetail.status)
    const [ticketLoading, setTicketLoading] = useState(false)

    const ticketOptions = [{ key: "open", value: "OPEN" }, { key: "in_progress", value: "IN PROGRESS" }, { key: "resolved", value: "RESOLVED" }]
    const handleStatusUpdate = (e) => {
        setTicketStatus(e.target.value)
    }

    const handleUpdate = () => {
        updateTicketStatus(ticketStatus, setTicketLoading, setAlertOpen, setMessage, setSeverity, ticketDetail._id, companyId, handleDetailClose)

    }

    return (
        <>
            <Dialog
                open={statusOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">Update ticket status</DialogTitle>
                <DialogContent>

                    <TextField
                        label="Status"
                        fullWidth
                        select
                        margin="normal"
                        name="status"
                        multiline
                        SelectProps={{
                            value: ticketStatus,
                            onChange: handleStatusUpdate
                        }}


                    >
                        {ticketOptions.map((dt) => {
                            return <MenuItem value={dt.key} key={dt.key}>{dt.value}</MenuItem>
                        })}

                    </TextField>



                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleUpdate}>{ticketLoading ? "loading.." : "Update"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
