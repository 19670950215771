import { useEffect, useMemo, useRef, useState } from "react";
import {
    GoogleMap,
    useLoadScript,
    MarkerF,
    InfoWindowF,
} from "@react-google-maps/api";
import { Box, Button, Typography } from "@mui/material";
import marker from './marker.svg'

const mapContainerStyles = {
    height: "100%",
    width: "100%",
};


const Map = ({ cityData, deviceData }) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    });
    const mapCenter = useMemo(() => (cityData), []);
    const [popup, setPopup] = useState(null);


    return (
        <>
            {!isLoaded ? <>Loading...</> : (
                <GoogleMap
                    zoom={13}
                    center={mapCenter}
                    mapContainerStyle={mapContainerStyles}
                    options={{
                        clickableIcons: false, // Prevent default behavior for marker icons
                    }}
                >
                    {deviceData.map((device) =>
                        <>
                            {device.location && (
                                <MarkerF
                                    key={device.device_id}
                                    position={{ lat: parseFloat(device.location.latitude), lng: parseFloat(device.location.longitude) }}
                                    icon={{
                                        url: marker,
                                        scaledSize: { width: 50, height: 50 },
                                    }}
                                    onClick={() => {
                                        setPopup(device);
                                    }}
                                />
                            )}
                        </>
                    )}
                    {popup && (
                        <InfoWindowF
                            key={popup.device_id}
                            position={{ lat: parseFloat(popup.location.latitude), lng: parseFloat(popup.location.longitude) }}
                            onCloseClick={() => setPopup(null)}
                        >
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle2">{popup.device_description}</Typography>
                                <Typography variant="subtitle1">
                                    {popup.device_id}
                                </Typography>
                                {/* <Button>Open</Button> */}
                            </Box>
                        </InfoWindowF>
                    )}
                </GoogleMap>
            )}
        </>
    );
};

export default Map;