import React, { useContext } from "react";
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import customTheme from "../../../theme";
import {
  AccountBalanceWallet,
  AppSettingsAlt,
  Campaign,
  ConfirmationNumber,
  Dashboard,
  DocumentScanner,
  LocalOffer,
  SettingsRemote,
  Speed,
  TravelExplore,
} from "@mui/icons-material";
import { UserContext } from "../../../context/UserContext";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BadgeIcon from "@mui/icons-material/Badge";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

let secondary = customTheme.palette.secondary.main;
let light = customTheme.palette.light.main;

const SidebarList = ({ mobileOpen, handleDrawerToggle }) => {
  const location = useLocation();
  const path = location.pathname;
  const { userData } = useContext(UserContext);
  const permission = userData.user.role_id?.permissions;

  const allowLocation = userData.user.company_id?.allow_location || false
  const allowTicketing = userData.user.company_id?.allow_ticketing || false

  const allow_metering = true;




  return (
    <div>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/dashboard">
          <img
            src="/assets/logo/Logo-Lateral-White.svg"
            alt="Luminox"
            style={{
              width: "auto",
              height: 36,
              marginBottom: -7,
            }}
          />
        </Link>
      </Toolbar>
      <Divider />
      <List>
        <ListItem
          button
          key={0}
          component={Link}
          to={"/dashboard"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path === "/dashboard"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard" ? { color: secondary } : { color: "#fff" }
            }
          >
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        <ListItem
          button
          key={1}
          component={Link}
          to={"/dashboard/master"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path === "/dashboard/master"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard/master"
                ? { color: secondary }
                : { color: "#fff" }
            }
          >
            <SettingsRemote />
          </ListItemIcon>
          <ListItemText primary={"Master Control"} />
        </ListItem>



        {permission !== undefined && permission.includes("FETCH_GROUP") && (
          <ListItem
            button
            key={2}
            component={Link}
            to={"/dashboard/group"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/group"
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/group"
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <WorkspacesIcon />
            </ListItemIcon>
            <ListItemText primary={"Group"} />
          </ListItem>
        )}
        {permission !== undefined && permission.includes("FETCH_USER") && (
          <ListItem
            button
            key={3}
            component={Link}
            to={"/dashboard/user"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/user"
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/user"
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <BadgeIcon />
            </ListItemIcon>
            <ListItemText primary={"User"} />
          </ListItem>
        )}
        {permission !== undefined && permission.includes("FETCH_ROLES") && (
          <ListItem
            button
            key={4}
            component={Link}
            to={"/dashboard/role"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/role"
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/role"
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <AccessibilityNewIcon />
            </ListItemIcon>
            <ListItemText primary={"Roles"} />
          </ListItem>
        )}

        {allow_metering === true && (
          <ListItem
            button
            key={5}
            component={Link}
            to={"/dashboard/energy"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/energy" || path.includes("/dashboard/energy/")
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/energy" || path.includes("/dashboard/energy/")
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <Speed />
            </ListItemIcon>
            <ListItemText primary={"Energy Metering"} />
          </ListItem>
        )}
        {allow_metering === true && (
          <ListItem
            button
            key={6}
            component={Link}
            to={"/dashboard/energy-notification"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/energy-notification"
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/energy-notification"
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <Campaign />
            </ListItemIcon>
            <ListItemText primary={"Energy Notification"} />
          </ListItem>
        )}


        {allowTicketing && permission !== undefined && permission.includes("VIEW_TICKET") && (
          <ListItem
            button
            key={3}
            component={Link}
            to={"/dashboard/tickets"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/tickets"
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/tickets"
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <ConfirmationNumber />
            </ListItemIcon>
            <ListItemText primary={"Tickets"} />
          </ListItem>
        )}

        <ListItem
          button
          key={7}
          component={Link}
          to={"/dashboard/plans"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path === "/dashboard/plans"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard/plans"
                ? { color: secondary }
                : { color: "#fff" }
            }
          >
            <LocalOffer />
          </ListItemIcon>
          <ListItemText primary={"Plans"} />
        </ListItem>

        <ListItem
          component={Link}
          button
          key={8}
          to={"/dashboard/transactions"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path === "/dashboard/transactions"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard/transactions"
                ? { color: secondary }
                : { color: "#fff" }
            }
          >
            <AccountBalanceWallet />
          </ListItemIcon>
          <ListItemText primary={"Transactions"} />
        </ListItem>

        <ListItem
          button
          key={9}
          component={Link}
          to={"/dashboard/logs"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path.includes("/dashboard/logs")
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path.includes("/dashboard/logs")
                ? { color: secondary }
                : { color: "#fff" }
            }
          >
            <DocumentScanner />
          </ListItemIcon>
          <ListItemText primary={"Logs"} />
        </ListItem>

        <ListItem
          component={Link}
          button
          key={10}
          to={"/dashboard/setting"}
          onClick={mobileOpen ? handleDrawerToggle : null}
          style={
            path === "/dashboard/setting" ||
              path === "/dashboard/setting/notification"
              ? { background: light, color: secondary }
              : { background: "none" }
          }
        >
          <ListItemIcon
            style={
              path === "/dashboard/setting" ||
                path === "/dashboard/setting/notification"
                ? { color: secondary }
                : { color: "#fff" }
            }
          >
            <AppSettingsAlt />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarList;




{/* ------- Location ------------------ */ }
{/* {allowLocation && (
          <ListItem
            button
            key={1}
            component={Link}
            to={"/dashboard/map"}
            onClick={mobileOpen ? handleDrawerToggle : null}
            style={
              path === "/dashboard/map"
                ? { background: light, color: secondary }
                : { background: "none" }
            }
          >
            <ListItemIcon
              style={
                path === "/dashboard/map"
                  ? { color: secondary }
                  : { color: "#fff" }
              }
            >
              <TravelExplore />
            </ListItemIcon>
            <ListItemText primary={"Device Location"} />
          </ListItem>
        )} */}

{/* -------------- ./Location ---------------- */ }