
import axiosIntance from "../helper/axios";

const getAllTickets = (
    company_id,
    setRows,
    setAlertOpen,
    setLoading,
    page,
    limit,
    setTotal,
    filter
) => {
    setLoading(true)
    axiosIntance
        .get(`/ticket/all/${company_id}?page=${page + 1}&limit=${limit}`)
        .then((res) => {
            setRows(res.data.data);
            setTotal(res.data.metadata.total_results);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err, 'errr')
            setLoading(false);
            setAlertOpen(true);
        });
};


const updateTicketStatus = (
    status, setLoading, setAlertOpen, setMessage, setSeverity, ticketId, companyId, handleClose
) => {

    setLoading(true)
    axiosIntance.put(`/ticket/update/status/${companyId}/${ticketId}`, { ticket_status: status }).then((res) => {
        setAlertOpen(true)
        setSeverity("success")
        setMessage("Status updated successfuly")
        setLoading(false)
        handleClose()


    }).catch((err) => {
        setLoading(false)
        setMessage(err.response.data.error || "Error in updating status")
        setAlertOpen(true)
        setSeverity("error")
    })
}


const assingUserTicket = (users, setLoading, setAlertOpen, setMessage, setSeverity, ticketId, companyId, handleClose) => {
    setLoading(true)
    axiosIntance.put(`/ticket/assign/user/${companyId}/${ticketId}`, { assigned_users: users }).then((res) => {
        setAlertOpen(true)
        setSeverity("success")
        setMessage("Users assigned successfuly")
        setLoading(false)
        handleClose()


    }).catch((err) => {
        setLoading(false)
        setMessage(err.response.data.error || "Error in assigning users")
        setAlertOpen(true)
        setSeverity("error")
    })
}

export { getAllTickets, updateTicketStatus,assingUserTicket };