// Log filters

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { assingUserTicket, updateTicketStatus } from "../../../api/ticket";
import { UserContext } from "../../../context/UserContext";
import { fetchAllUserCompany } from "../../../api/users";

export default function AssignUser({
    statusOpen,
    handleClose,
    ticketDetail,
    setAlertOpen,
    setSeverity,
    setMessage,
    handleDetailClose

}) {

    const { userData } = useContext(UserContext);
    const companyId = userData.user.company_id._id
    const [ticketLoading, setTicketLoading] = useState(false)
    const [userArray, setUserArray] = useState([])
    const [userLoading, setUserLoading] = useState(false)
    const ticketUsers = ticketDetail.assigned_users.map((dt) => dt._id)


    const [formState, setFormState] = React.useState({
        userArray: ticketUsers,
    });

    const handleFieldChange = (event) => {
        setFormState((formState) => ({
            ...formState,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        }));
    };

    useEffect(() => {
        fetchAllUserCompany(companyId, 0, 0, false, setUserArray, setUserLoading)
    }, [])




    const handleUpdate = () => {
        assingUserTicket(formState.userArray, setTicketLoading, setAlertOpen, setMessage, setSeverity, ticketDetail._id, companyId, handleDetailClose)

    }

    return (
        <>
            <Dialog
                open={statusOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title">Update ticket status</DialogTitle>
                <DialogContent>

                    <TextField
                        // classes={{ root: classes.root }}
                        disabled={userLoading}
                        margin="normal"
                        fullWidth
                        select
                        name="userArray"
                        id="userArray"
                        variant="outlined"
                        label="User Array"
                        SelectProps={{
                            multiple: true,
                            value: formState.userArray,
                            onChange: handleFieldChange,
                        }}
                    >
                        {userArray.map((data) => {
                            return <MenuItem value={data._id} key={data._id}>{data.full_name}</MenuItem>;
                        })}
                    </TextField>



                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleUpdate}>{ticketLoading ? "loading.." : "Update"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
